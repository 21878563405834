//
//
//
//
//
//
//
//

export default {
    props: {
        roleid: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            form:{
                roleid: '',
            },
        }
    },
    computed: {

    },
	methods:{
		queryData: function(){
			var that = this;
			this.$get(this.$store.getters.sysUrl + '/role/detail', {rowguid: this.form.roleid}, function(data){
				that.form = data;
			})
		},
		submit: function(){
			this.$post(this.$store.getters.sysUrl+ '/role/add', this.form, function(data, res){
				ShowMsg( res.msg, 'success');
				this.cancle();
			})
		},
		cancle: function(){
			this.$emit('close');
		}
	},
	mounted:function(){
		this.form.roleid = this.roleid;
		!!this.form.roleid && this.queryData();
	}
}
