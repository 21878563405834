//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        'value': {

        },
        'title2': {
            type: String,
            default: '已选择'
        },
        'url': {

        },
        'props': {

        }
    },
    data () {
        return {
            data: [],
            reprops: {
                key: !!this.props ? this.props.key : 'userid',
                label: !!this.props ? this.props.label : 'truename'
            },
            map: {}
        }
    },
    computed: {
        value1: {
            get: function () {
                return this.value
            },
            set: function (e) {
                this.$emit('input',e)
            }
        },
    },
    watch:{
        url:function(){
            this.query();
        }
    },
    methods: {
        filterMethod: function(query, item) {
            return item.truename.indexOf(query) > -1;
        },
        query: function(){
            var that = this;
            this.$get(this.$store.getters.sysUrl + "/user/select", function(data){
                that.data = data;
                data.forEach(function(item){
                    that.map[item[that.reprops.key]] = item;
                })
            })
        },
        transferChange: function(e){
            var that = this;
            var res = [];
            e.forEach(function(item){
                res.push(that.map[item]);
            });
            this.$emit('change', res);
        }
    },
    mounted: function(){
       this.query();
    }
}
