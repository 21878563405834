//
//
//
//
//
//
//
//

import selectPeople from './component/select-people'
export default {
    components: {selectPeople},
    props: {
        type: {
            type: String,
            default: ''
        },
        roleid: {
            type: String,
            default: ''
        },
    },
    data () {
        return {
            form: {
				people: []
			},
			url:"",
        }
    },
    computed:{
		switchObj:function(){
			var that=this;
			return {
				User: function(type){
					if(type=="submit"){
						that.ajaxSubmit(that.$store.getters.sysUrl+'/role/allotuser');
					}else if(type=="query"){
						that.ajaxQuery(that.$store.getters.sysUrl+'/role/userrolelist');
					}else if(type=="url"){
						return "";
					}else{
						that.dialogCancle();
					}
				},
				TempUser: function(type){
					if(type=="submit"){
						that.ajaxSubmit(that.$store.getters.sysUrl+'/role/tempallotuser');
					}else if(type=="query"){
						that.ajaxQuery(that.$store.getters.sysUrl+'/role/tempuserrolelist');
					}else if(type=="url"){
						return "";
					}else{
						that.dialogCancle();
					}
				},
				Xmcy:function(type){
					if(type=="submit"){
						var idarr=[],
                            textarr=[],
                            arr;

						$.each(that.form.people,function(index,item){
							arr=item.split("/");
							idarr.push(arr[0]);
							textarr.push(arr[1]);
						});

                        let obj = {};
                        obj[getSearch('idfield')] = idarr.join(',');
                        obj[getSearch('textfield')] = textarr.join(',');
                        this.$emit('update', obj);
						// Vue.set(window.parent.$vue.$data.form,getSearch('idfield'),idarr.join(','));
						// Vue.set(window.parent.$vue.$data.form,getSearch('textfield'),textarr.join(','));
						that.$nextTick(function(){
							that.dialogCancle();
						})
					}else if(type=="query"){
						var ids=getSearch('peopleids');
						var idarr=ids?ids.split(","):[];
						var names=decodeURI(getSearch('peoplenames'));
						var namearr=names?names.split(","):[];
						that.form.people = idarr.map(function(item,index){
							return item+"/"+namearr[index];
						})
					}else if(type=="url"){
						return "/user/selectspecial";
					}
					else{
						that.dialogCancle();
					}
				},
				PlanUser:function(type){
					if(type=="submit"){
						var idarr=[],textarr=[],arr;
						$.each(that.form.people,function(index,item){
							arr=item.split("/");
							idarr.push(arr[0]);
							textarr.push(arr[1]);
						});
						this.$emit('select', {
							id: idarr.join(','),
							name: textarr.join(','),
							caller: getSearch('caller')
						});
						that.$nextTick(function(){
							that.dialogCancle();
						})
					}else if(type=="query"){
						var ids = getSearch('peopleids');
						var idarr=ids ? ids.split(",") : [];
						var names=decodeURI(getSearch('peoplenames'));
						var namearr=names?names.split(","):[];
						if(idarr[0]==='null' && namearr[0]==='null'){
							idarr.splice(0, 1);
							namearr.splice(0, 1);
						}
						that.form.people = idarr.map(function(item,index){
							return item+"/"+namearr[index];
						})
					}else if(type=="url"){
						return "/user/selectspecial";
					}
					else{
						that.dialogCancle();
					}
				},
				watch: function(type) {
					if(type == 'submit') {
						var ids = that.form.people.join(',');
						that.$post(that.$store.getters.sysUrl + '/watch/allotuser', {
							rowguid: getSearch('rowguid'),
							userids: ids
						}, function(data, res) {
							ShowMsg.call(that, res.msg);
							that.$refs.form.close();
						})
					}else if(type=="query") {
						that.$get(that.$store.getters.sysUrl+'/watch/watchuserlist', {
							rowguid: getSearch('rowguid')
						}, function(data) {
							data = data || [];
							that.form.people = data.map(function(item){
								return item.userid;
							})
						})
					}else if(type=="url") {
						return "";
					}else {
					
                    }
				}
			}
		}
	},
	methods:{
		dialogSubmit: function(url){
			this.switchObj[this.type]("submit")
        },
        ajaxSubmit:function(url){
            var that = this;
            that.$post(url, {
                rowguid: this.roleid,
                userids: that.form.people.join(',')
            }, function(data){
                ShowMsg.call(that, '分配完成', 'success');
                that.dialogCancle();
            })
        },
        ajaxQuery:function(url){
            var that = this;
            that.$get(url,{
                rowguid: this.roleid
            }, function(data){
                that.getDataCallback(data);
            })
        },
        dialogCancle: function(){
            this.$emit('close')
        },
		getDataCallback: function(data){
			data = data || [];
			this.form.people = data.map(function(item){
				return item.userid;
			})
		}
	},
	mounted:function(){
		var that = this;
		this.switchObj[this.type]("query");
	}
}
