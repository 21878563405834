//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        roleid: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            form: {
				menu: []
			},
			type: ''
        }
    },
    computed:{
		typename:function(){
			return getSearch('type');
		}
	},
	methods:{
		dialogSubmit: function(){
            var that = this;

			var menuids, noRoot = [];
			that.form.menu.forEach(function(item) {
				if(!that.form.menu.some(function(code) {
					return ((code.indexOf(item) > -1) && (code.indexOf(item) == 0) && (code != item))
				}) ){
					noRoot.push(item)
				}
			});
			menuids = noRoot.join(',');

			that.$post(this.$store.getters.sysUrl + '/role/allotmenu', {
				rowguid: this.roleid,
				menuids: menuids
			}, function(data){
				ShowMsg.call(this, '权限分配完成', 'success');
				this.dialogCancle();
			})

        },
        dialogCancle: function(){
            this.$emit('close');
        },
		getDataCallback: function(data){
			data = data || [];
			this.form.menu = data.map(function(item){
				return item.menuid;
			})
		}
	},
	mounted:function(){
		var that = this;
		this.type = getSearch('type');

        that.$get(this.$store.getters.sysUrl + '/role/rolemenulist', {
            rowguid: this.roleid
        }, function(data){
            that.getDataCallback(data);
        })
	}
}
