//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import add from './add'
import menuList from './menu'
import selUser from '../user/form'
export default {
    components:{add , menuList , selUser},
    props: ['disabled'],
    data () {
            var checkTable = function (rule, value, callback) {
            $(value).each(function (index) {
                if (this.rolename == '') {
                    return callback(new Error('角色名称不能为空'));
                }
            })
            callback();
        }
        return{
             rules: {
                tableData: [
                    { validator: checkTable, trigger: 'blur' }
                ]
            },
            form:{
                tableData:[]
            },
            //搜索条件
            pgData: {
                sortname: '',
                sortorder: ''
            },

            formInline:{
                rolename: '',
                truename: '',
                username: ''
            },

            dialogPeopleVisible:false,
            dialogMenuVisible:false,
            dialogAddVisible: false,
            formUrl_prople: '',
            formUrl_menu: '',

            formTitle: '',
            formTitle_add: '',
            formTitle_people: '',
            formTitle_menu: '',

            editAllController:0,//全部编辑控制器

            //字典map
            mapMenu: [],
            mapPeople: {},
            editRoleid: '',
        }
    },
    computed:{
        pgQuery: function(){
            return {
                pagesize: 10,
                sortname: this.pgData.sortname ? this.pgData.sortname : 'addtime',
                sortorder: this.pgData.sortorder ? this.pgData.sortorder : 'desc',

                title: this.formInline.rolename,
                username: this.formInline.username
            }
        },
    },

    watch:{
        dialogPeopleVisible: function(n, o){
            !n && this.$refs.page.reload();
        },
        dialogAddVisible: function(n, o){
            !n && this.$refs.page.reload();
        },
        dialogMenuVisible: function(n, o){
            !n && this.$refs.page.reload();
        }
    },

    methods: {
        doSearch: function(){
            this.$refs.page.queryData();
        },
        //添加角色
        newPeople: function() {
            this.editRoleid = '';
            this.formTitle_add = '添加角色';
            this.dialogAddVisible = true;
        },
        //编辑人员
        toEditRow: function(row){
            this.editRoleid = row.roleid;
            this.formTitle_add = '编辑角色名';
            this.dialogAddVisible = true;
        },
        //删除人员
        handleDel: function (row) {
            this.$post(this.$store.getters.sysUrl + '/role/del', {rowguid: row.roleid}, function(){
                ShowMsg('删除成功', 'success');
                this.$refs.page.queryData();
            })
        },

        //分配权限
        editMenu:function(row){
            this.editRoleid = row.roleid;
            this.dialogMenuVisible = true;
        },

        editPeople:function(row){
            this.editRoleid = row.roleid;
            this.dialogPeopleVisible = true;
        },

        queryZd: function() {
            var that = this;
            this.$get(this.$store.getters.sysUrl + '/role/getmenutree', function(data){

                function deepMap(arr) {
                    arr.forEach(function(item) {
                        if(!!item.children) {
                            that.mapMenu.push(item);
                            deepMap(item.children);
                        }else{
                            var citem = clone(item);
                            citem.leaf = true;
                            that.mapMenu.push(citem);
                        }
                    })
                }

                deepMap(data);
            });
            this.$get(this.$store.getters.sysUrl+'/user/select', function(data){
                data.forEach(function(item){
                    that.mapPeople[item.userid] = item.truename;
                })
            });
        },

        afterQuery: function(data){
            data.forEach(function(item){
                item.menuList = [];
                item.peopleList = {};
            })
        },
        menuPopoverShow: function(row) {
            var that = this;
            this.$get(this.$store.getters.sysUrl + '/role/rolemenulist', {
                rowguid: row.roleid
            }, function(data){
                console.log(111,data);
                var resArr = [];
                var dataMap = [];
                data.forEach(function(item) {
                    dataMap.push(item.menuid);
                });

                that.mapMenu.forEach(function(row) {
                    dataMap.forEach(function(item) {
                        if(row.id == item && resArr.indexOf(row) == -1) {
                            resArr.push(row)
                        };
                        if(row.id == item.slice(0, 3) && resArr.indexOf(row) == -1){
                            resArr.push(row)
                        };
                    })
                })
                row.menuList = resArr;
                return resArr;
            })
        },
        peoplePopoverShow: function(row) {
            this.$get(this.$store.getters.sysUrl+'/role/roleusermap', {
                rowguid: row.roleid
            }, function(data){
                row.peopleList = data;
            })
        }
    },
    mounted() {
        this.queryZd();
    }
}
